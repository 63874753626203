<template>
  <div>
    <Loading :loading="!user" />
    <h4 class="title is-4">Account Settings</h4>

    <AccountStatus v-if="user" :user="user" />

    <div class="box" v-if="user">
      <h6 class="title is-6">Account Information</h6>
      <div class="columns is-multiline">
        <!-- first name -->
        <div class="column is-6">
          <Field
            :retry="retryAccount"
            :label="'First name'"
            :placeholder="'First name'"
            v-bind.sync="account.firstName"
            :required="false"
            :tooltip="`
              Your name will appear on form requests and will be auto-filled
              when sending forms.
            `"
          />
        </div>

        <!-- last name -->
        <div class="column is-6">
          <Field
            :retry="retryAccount"
            :label="'Last name'"
            :placeholder="'Last name'"
            v-bind.sync="account.lastName"
            :required="false"
            :tooltip="`
              Your name will appear on form requests and will be auto-filled
              when sending forms.
            `"
          />
        </div>

        <!-- email -->
        <!-- TODO probably should send a verification email before allowing change -->
        <div class="column is-12">
          <Field
            :label="'Email'"
            :retry="retryAccount"
            :placeholder="'Email'"
            v-bind.sync="account.email"
            :func="'email'"
            :path="'/user/email/settings'"
            :tooltip="`
              This is the email address you use to log in to your account. If you 
              change your email address here, you will need to use your new email
              address next time you log in.
            `"
          />
        </div>

        <div class="column is-12">
          <Select
            v-bind.sync="account.country"
            :options="selectCountry"
            :label="'Country'"
            :placeholder="'Select country'"
            :required="false"
            @change="updateCountry()"
          />
        </div>

        <!-- address -->
        <div class="column is-12">
          <Field
            :label="'Address'"
            :retry="retryAccount"
            :placeholder="'Address'"
            :func="'address'"
            v-bind.sync="account.address"
            :required="false"
          />
        </div>

        <!-- city -->
        <div class="column is-4">
          <Field
            :label="'City'"
            :retry="retryAccount"
            :placeholder="'City'"
            v-bind.sync="account.city"
            :required="false"
          />
        </div>

        <!-- us state -->
        <div class="column is-4" v-if="account.country.value == 'us'">
          <Select
            v-bind.sync="account.state"
            :options="selectState"
            :label="'State'"
            :placeholder="'Select state'"
            :required="false"
            :retry="retryAccount"
          />
        </div>

        <!-- non-us state -->
        <div class="column is-4" v-else>
          <Field
            :label="'State or province'"
            :retry="retryAccount"
            :placeholder="'State or province'"
            v-bind.sync="account.state"
            :required="false"
          />
        </div>

        <!-- zip code -->
        <div class="column is-4" v-if="account.country.value == 'us'">
          <Field :label="'Zip code'" v-bind.sync="account.postalCode" :func="'zip'" />
        </div>

        <!-- postal code -->
        <div class="column is-4" v-else>
          <Field
            :label="'Postal code'"
            :retry="retryAccount"
            :placeholder="'Postal code'"
            v-bind.sync="account.postalCode"
            :required="false"
          />
        </div>

        <!-- buttons -->
        <div class="column is-12">
          <div class="buttons">
            <button
              class="button is-link"
              @click="saveAccount()"
              :disabled="!accountValid"
            >
              Save Account Information
            </button>
            <button class="button" @click="reset()">Discard Changes</button>
          </div>
        </div>
      </div>
    </div>
    <!-- end box -->

    <!-- TODO separate by public/private information and add a "requester name" or something -->
    <div class="box" v-if="user">
      <h6 class="title is-6">Business Information</h6>

      <div class="columns is-multiline">
        <div class="column is-6">
          <Logo v-bind:logo.sync="user.logo" />
        </div>

        <!-- company name -->
        <div class="column is-12">
          <Field
            :label="'Business name'"
            :placeholder="'Business name'"
            v-bind.sync="business.businessName"
            :required="false"
            :tooltip="`
              Your business name will appear on form requests.
            `"
          />
        </div>

        <!-- tax id country -->
        <div class="column is-6">
          <Select
            v-bind.sync="business.businessCountry"
            :options="selectCountry"
            :label="'Tax ID Country'"
            :placeholder="'Select country'"
            :required="false"
            :tooltip="`
              Specify the country for your tax ID number.
            `"
            @update="retryTaxId++"
          />
        </div>

        <!-- tax id -->
        <div class="column is-6">
          <Field
            :retry="retryTaxId"
            :label="'Tax ID'"
            :placeholder="'Tax ID'"
            v-bind.sync="business.taxId"
            :func="'tin'"
            :path="'/validate'"
            :meta="{
              user,
              iso: business.businessCountry.value,
              unavailable: 'valid',
            }"
            :required="false"
            :tooltip="'Your tax ID number will appear on invoices. It is kept private.'"
          />
        </div>

        <!-- buttons -->
        <div class="column is-12">
          <div class="buttons">
            <button
              class="button is-link"
              @click="saveBusiness()"
              :disabled="!businessValid"
            >
              Save Business Information
            </button>
            <button class="button" @click="reset()">Discard Changes</button>
          </div>
        </div>
      </div>
    </div>

    <FlashMessage :flash="flash" />
  </div>
</template>

<style scoped>
input.input:disabled {
  cursor: default;
}

textarea.textarea:disabled {
  cursor: default;
}
</style>

<script>
import { api, field, select } from '@/modules/util'
import selectCountry from '@/modules/selectCountry.json'
import selectState from '@/modules/selectState.json'
import Logo from '@/components/Logo.vue'

export default {
  name: 'Settings',
  components: { Logo },
  computed: {
    accountValid() {
      return (
        this.account.firstName.valid &&
        this.account.lastName.valid &&
        this.account.email.valid &&
        this.account.country.valid &&
        this.account.address.valid &&
        this.account.city.valid &&
        this.account.state.valid &&
        this.account.postalCode.valid
      )
    },
    businessValid() {
      return (
        this.business.businessName.valid &&
        this.business.businessCountry.valid &&
        this.business.taxId.valid
      )
    },
  },
  data() {
    return {
      user: null,
      selectCountry,
      selectState,
      retryAccount: 0,
      retryTaxId: 0,
      account: {
        firstName: field(),
        lastName: field(),
        email: field(),
        country: select(),
        address: field(),
        city: field(),
        state: field(),
        postalCode: field(),
      },
      business: {
        businessName: field(),
        businessCountry: select(),
        taxId: field(),
      },
      flash: [],
    }
  },
  // watch: {
  //   user: {
  //     immediate: true,
  //     handler() {
  //       if (this.user) {
  //         this.account.firstName.value = this.user.firstName
  //         this.account.lastName.value = this.user.lastName
  //         this.account.email.value = this.user.email
  //         this.account.country.value = this.user.selectCountry
  //         this.account.address.value = this.user.address
  //         this.account.city.value = this.user.city
  //         this.account.state.value = this.user.state
  //         this.account.postalCode.value = this.user.postalCode
  //         this.business.businessName.value = this.user.businessName
  //         this.business.businessCountry.value = this.user.businessCountry
  //         this.business.taxId.value = this.user.taxId
  //       }
  //     },
  //   },
  // },
  methods: {
    async init() {
      try {
        const res = await api.get('/users/settings')
        if (!res.data.user) throw 'No user.'
        this.user = res.data.user
        this.account.firstName.value = this.user.firstName
        this.account.lastName.value = this.user.lastName
        this.account.email.value = this.user.email
        this.account.country.value = this.user.country
        this.account.address.value = this.user.address
        this.account.city.value = this.user.city
        this.account.state.value = this.user.state
        this.account.postalCode.value = this.user.postalCode
        this.business.businessName.value = this.user.businessName
        this.business.businessCountry.value = this.user.businessCountry
        this.business.taxId.value = this.user.taxId
      } catch (err) {
        this.$emit('init')
        this.$router.push({ name: 'Login' })
      }
    },
    async saveAccount() {
      try {
        await api.post('/users/settings/account', { account: this.account })
        // this.init()
        this.$emit('init')
        this.flash.push({ msg: 'Account information saved.' })
      } catch (err) {
        this.flash.push({ msg: err, type: 'error' })
      }
    },
    async saveBusiness() {
      try {
        await api.post('/users/settings/business', { business: this.business })
        // this.init()
        this.$emit('init')
        this.flash.push({ msg: 'Business information saved.' })
      } catch (err) {
        this.flash.push({ msg: err, type: 'error' })
      }
    },
    reset() {
      this.init()
      this.retryAccount++
      this.retryTaxId++
    },
    updateCountry() {
      if (this.account.country.value == 'us') {
        this.account.state = select({ valid: true })
        this.account.postalCode = field({ valid: true })
      } else {
        this.account.state = field({ valid: true })
        this.account.postalCode = field({ valid: true })
      }
    },
  },
  mounted() {
    this.init()
  },
}
</script>
