<template>
  <div>
    <div class="logo-label">Logo</div>
    <div v-if="logo" class="logo">
      <img
        @click="$refs.fileBrowser.click()"
        :src="images + logo"
        @error="reloadLogo()"
      />
      <button class="delete" @click="deleteLogo()"></button>
    </div>
    <div class="upload" v-else @click="$refs.fileBrowser.click()">
      <i class="fas fa-plus"></i> Upload
    </div>
    <input
      ref="fileBrowser"
      type="file"
      style="display: none"
      accept=".jpg,.jpeg,.png"
      @change="uploadLogo"
    />
    <FlashMessage :flash="flash" />
  </div>
</template>

<style scoped>
.logo-label {
  margin-bottom: 7px;
  font-weight: bold;
  font-size: 0.75rem;
}
.upload {
  width: 100%;
  padding: 60px;
  border: 1px dashed #b5b5b5;
  border-radius: 4px;
  text-align: center;
  color: #dbdbdb;
}
.upload:hover {
  cursor: pointer;
  color: #b5b5b5;
  border-color: #b5b5b5;
}
.logo img {
  max-height: 100px;
  max-width: 250px;
}
.logo img:hover {
  cursor: pointer;
  opacity: 0.8;
}
</style>

<script>
import { api, images, pause } from '@/modules/util'
export default {
  name: 'Logo',
  props: ['logo'],
  data() {
    return {
      images,
      flash: [],
      newLogo: '',
    }
  },
  methods: {
    async deleteLogo() {
      try {
        await api.delete('/users/logo')
        this.$emit('update:logo', '')
        this.flash.push({ msg: 'Logo deleted.' })
      } catch (err) {
        this.flash.push({ msg: err, error: true })
      }
    },
    async uploadLogo(e) {
      try {
        // set the file
        const file = e.target.files[0]
        if (!file) throw 'Something went wrong.'

        // reset the filebrowser
        this.$refs.fileBrowser.value = ''

        // validate the image
        await this.validateImage(file)

        // get the signed url
        const res = await api.post('/users/logo', {
          file: { name: file.name },
        })

        this.flash.push({ msg: 'Uploading, please wait...' })

        // upload the file
        await fetch(res.data.url, {
          method: 'PUT',
          headers: { 'Content-Type': file.type },
          body: file,
        })

        this.flash.push({ msg: 'Upload complete, processing...' })
        this.newLogo = res.data.logo
        this.reloadLogo()

        // this.$emit('update:logo', res.data.logo)
      } catch (err) {
        this.flash.push({ msg: err, type: 'error' })
      }
    },
    // retry image until it is available
    async reloadLogo() {
      this.$emit('update:logo', '')
      await pause(3000)
      this.$emit('update:logo', this.newLogo)
    },
    async validateImage(file) {
      return new Promise((resolve, reject) => {
        if (file.size > 20000000) reject('Maximum file size is 20 MB.')
        const img = new Image()
        img.src = window.URL.createObjectURL(file)
        img.onload = () => {
          const width = img.naturalWidth
          const height = img.naturalHeight
          window.URL.revokeObjectURL(img.src)
          if (width * height < 2500) reject('Image should be at least 50 x 50 pixels.')
          resolve()
        }
      })
    },
  },
}
</script>
